import { fetchAuthenticatedContent } from "@parallelpublicworks/entitysync";

export default async (auth, dispatch, email) => {
  const jsonapiEndpoint = 'node/lor_request/?filter[field_request_email]=' + email;
  const method = "GET"; 
  if (typeof window !== `undefined`) {
    const resp = await fetchAuthenticatedContent(auth, dispatch, jsonapiEndpoint, method);
    const body = resp.data;
    return body;
  }

  return false;
}
